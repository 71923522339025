import React from "react";
import "./footer.css";
import Logo from "../../imgs/foomigate1.svg";
import Social1 from "../../imgs/twitter.svg";
import Social2 from "../../imgs/instagram.svg";
import Social3 from "../../imgs/facebook.svg";
import Social4 from "../../imgs/google.svg";
import { Link } from "react-router-dom";

const Foot = () => {
  return (
    <div className="footer">
      <div className="up-footer">
        <div className="footer-main">
          <Link to="/" className="logo-div">
            <img src={Logo} alt="" className="foot-logo" />
          </Link>
          <p className="footer-head">
            The 1st Indigenous Pest Control Technology Platform
          </p>
          <div className="socials">
            <a
              href="https://x.com/foomigeit?s=21"
              rel="noreferrer"
              target="_blank"
            >
              <img src={Social1} alt="" />
            </a>
            <a
              href="https://instagram.com/foomigeit?igshid=MzRIODBiNWFIZA=="
              rel="noreferrer"
              target="_blank"
            >
              <img src={Social2} alt="" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100092396327355"
              rel="noreferrer"
              target="_blank"
            >
              <img src={Social3} alt="" />
            </a>
            <a href="https://g.co/kgs/ahBsB9" rel="noreferrer" target="_blank">
              <img src={Social4} alt="" />
            </a>
          </div>
          <p className="copyright">© Copyright 2023 All Rights Reserved</p>
        </div>

        <div className="footer-nav">
          <div className="footer-list">
            <p className="footer-title">Quick links</p>
            <Link to="/#about" className="footer-link">
              About
            </Link>
            <Link to="/#services" className="footer-link">
              Services
            </Link>
            <Link to="/#download" className="footer-link">
              Get Started
            </Link>
            <Link className="footer-link" to="/#testimonial">
              Testimonial
            </Link>
          </div>

          <div className="footer-list">
            <p className="footer-title">Address</p>
            <p className="footer-link reduced">
              10, Wole Ogunjimi, Opebi - Ikeja, Lagos.
            </p>
          </div>

          <div className="footer-list">
            <p className="footer-title">Contact</p>
            <p className="footer-link">+234 913 682 0031</p>
            <p className="footer-link">hello@foomigeit.com</p>
          </div>
        </div>
      </div>
      <div className="down-footer">
        <Link to="/terms" className="footer-end">
          Terms & Condition
        </Link>
        <Link to="/privacy" className="footer-end">
          Privacy policies
        </Link>
      </div>
    </div>
  );
};

export default Foot;
