import React from "react";
import Phone from "../../imgs/phone.svg";
import "./steps.css";
import pencil from "../../design/steps-pencil.svg";
import dots from "../../design/steps-dot.svg";
import ring from "../../design/steps-ring.svg";
import circle from "../../design/steps-circle.svg";
import frame from "../../design/steps-frame.svg";

const Steps = () => {
  return (
    <div className="steps">
      <img src={pencil} alt="" className="pencil" />
      <p className="steps-title">How it works</p>
      <p className="steps-sub">
        <span>Our Fumigation process is designed</span> to ensure the highest
        level of effectiveness and safety for our clients. Our process consists
        of the following steps:
      </p>

      <div className="steps-flex">
        <div className="steps-flow">
          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Download our app</p>
            </div>
            <div className="step-body-1">
              As part of our process, we encourage customers to download our
              app, which provides a quick and efficient way to access our
              services.
            </div>
          </div>

          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Select your apartment</p>
            </div>
            <div className="step-body-1">
              Select the appropriate apartment type for their pest control
              needs, whether it's a single-family home, multi-unit apartment
              complex, or commercial building.
            </div>
          </div>

          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Fumigation</p>
            </div>
            <div className="step-body-1">
              We use safe and effective fumigants to eradicate pests from your
              property.
            </div>
          </div>

          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Fumigation Plans</p>
            </div>
            <div className="step-body-1">
              Our plan outlines the scope of work, recommended treatment
              methods, and safety procedures to ensure effective and safe pest
              control.
            </div>
          </div>

          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Schedule a Fumigation service</p>
            </div>
            <div className="step-body-1">
              Clients can easily schedule a fumigation service through mobile
              app. Clients can find the most convenient date and time for the
              service and provides clear instructions on how to prepare for the
              treatment.
            </div>
          </div>

          <div className="step-card-1">
            <div className="step-head-1">
              <div className="step-icon-1"></div>
              <p className="step-header-1">Make a payment</p>
            </div>
            <div className="step-body-1">
              Make payment for our services online through our mobile app. We
              offer secure and flexible payment options to ensure a hassle-free
              and seamless experience for our clients.
            </div>
          </div>
        </div>

        <div className="step-section">
          <img src={dots} alt="" className="dots" />
          <img src={frame} alt="" className="frame" />
          <img src={circle} alt="" className="circle" />
          <img src={ring} alt="" className="ring" />
          <img src={Phone} alt="" className="phone" />
        </div>
      </div>
    </div>
  );
};

export default Steps;
