import React from "react";
import "./testimonial.css";
import pencil from "../../design/steps-pencil.svg"
import frame from "../../design/serv-frame.svg"

const Testimonial = () => {
  return (
    <div className="testimonial" id="testimonial">
      <p className="testimonial-title">What Our Customers have to say</p>

      <div className="testimonial-contain">
        <img src={pencil} alt="" className="pencil"/>

        <div className="testimonial-card">
          <p className="testimonial-body">
            I came across @foomigeit last week and it's been amazing. Downloaded
            their app, didn't understand it. <br />
            I requested for an explanation and the Customer care representative
            was so nice, kind, sweet and friendly. I was so impressed with the
            way she was patient.I asked her name, she said VIVIAN. <br /> Thank
            you Foomigeit for having such a great and amazing lady as your
            customer care rep.
            <br />I understood everything and all questions were answered so
            intelligently.She deserves some awards and increment in her pay.
            Great team. You guys are great and doing well.
          </p>
          <div className="testimonial-person">
            <p className="testimonial-name">Victor Elijah Ogochukwu</p>
            <p className="testimonial-tag">Client</p>
          </div>
        </div>

        <div className="testimonial-card">
          <p className="testimonial-body side">
            Thanks, I really enjoyed your process. If you see how the
            cockroaches are falling. <br /> <br /> It works like magic.
          </p>
          <div className="testimonial-person">
            <p className="testimonial-name">Mr Cosmos</p>
            <p className="testimonial-tag">Client - Bariga</p>
          </div>
        </div>

        <div className="testimonial-card">
          <p className="testimonial-body side">
            They did a perfect job yesterday. I am grateful. God bless you.{" "}
            <br /> <br/>
            Foomigeit is the best!.
          </p>
          <div className="testimonial-person">
            <p className="testimonial-name">Mrs Joy Nkem</p>
            <p className="testimonial-tag">Client - Berger</p>
          </div>
        </div>

        <div className="testimonial-card">
          <p className="testimonial-body side">
            "I am glad I invited foomigeit over to my apartment. Mosquitoes have
            virtually the best corners in my house But changed all that for me.
            Now we can sleep conveniently without worries of disturbing
            mosquitoes. Thank you foomigeit"
          </p>
          <div className="testimonial-person">
            <p className="testimonial-name">Engr. Uche</p>
            <p className="testimonial-tag">Client - Mile 12</p>
          </div>
        </div>

        <img src={frame} alt="" className="frame"/>
      </div>
    </div>
  );
};

export default Testimonial;
