import React from "react";
import "./about.css";
import Img1 from "../../imgs/about1.svg";
import Img2 from "../../imgs/about2.png";
import Circle1 from "../../imgs/circle1.png";
import Circle2 from "../../imgs/circle2.png";
import Small1 from "../../imgs/small1.png";
import Small2 from "../../imgs/small2.png";
import pencil from "../../design/abt-pencil.svg";
import vector from "../../design/abt-vector.svg";
import frame from "../../design/abt-frame.svg";
import circle from "../../design/abt-circle.svg";

const About = () => {
  return (
    <div className="about" id="about">
      <img src={pencil} alt="" className="pencil" />
      <div className="about-txt">
        <p className="about-title">About Us</p>
        <p className="about-header">
          At <span>Foomigeit,</span>
        </p>
        <div className="about-sub">
          <span>We help you protect your home or business</span> from unwanted
          pests with our professional fumigation services. Our experienced team
          uses safe and effective methods to eliminate a wide range of pests,
          including termites, bed bugs, roaches, and more. With our
          comprehensive solutions and personalized approach, you can trust us to
          keep your property pest-free.
          <img src={vector} alt="" className="vector" />
        </div>
      </div>

      <div className="about-section">
        <img src={frame} alt="" className="about-des1" />
        <img src={frame} alt="" className="about-des4" />
        <img src={Img1} alt="" className="about-img1" />
        <img src={Small1} alt="" className="about-img2" />
        <img src={Circle1} alt="" className="about-img3" />
        <img src={Img2} alt="" className="about-img4" />
        <img src={Small2} alt="" className="about-img5" />
        <img src={Circle2} alt="" className="about-img6" />
        <img src={circle} alt="" className="about-des2" />
        <img src={circle} alt="" className="about-des3" />
      </div>
    </div>
  );
};

export default About;
