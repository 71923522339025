import React from "react";
import Image from "../../imgs/hero-svg.svg";
import "./hero.css";
import { Link } from "react-router-dom";
import design from "../../design/hero-pencil.svg";
import circles from "../../design/hero-circles.svg";
import vector from "../../design/hero-vector.svg";
import frame from "../../design/hero-frame.svg";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-txt">
        <div className="hero-header">
          Professional <span>Fumigation</span> Service for a{" "}
          <span>Pest-Free</span> Environment.
          <img src={design} alt="" className="pencil" />
          <img src={circles} alt="" className="circles" />
        </div>
        <div className="hero-sub">
          Protect your home or business with our expert fumigation services. Our
          experienced professionals use advanced techniques and equipment to
          thoroughly and safely treat your space. Say goodbye to termites, bed
          bugs, and other pests and enjoy a pest-free environment.
          <img src={vector} alt="" className="vector" />
        </div>
        <Link className="nav-btn hero-move" to="/#treatment">
          Get Started
        </Link>
      </div>

      <div className="image-section">
        <img src={Image} alt="" className="hero-img" />

        <img src={frame} alt="" className="hero-frame"/>
      </div>
    </div>
  );
};

export default Hero;
