import { Link } from "react-router-dom";
import "./pricing.css";
import check from "../../imgs/check.svg";
import pencil from "../../design/serv-pencil.svg"
import circles from "../../design/serv-circles.svg"

const Pricing = () => {
  const data = [
    {
      room: "1 Bedroom apartment",
      price: "20,000.00",
      type: "Water Based Fumigation",
    },
    {
      room: "2 Bedroom apartment",
      price: "32,000.00",
      type: "Smoke Based Fumigation",
    },
    {
      room: "3 Bedroom apartment",
      price: "45,000.00",
      type: "Smoke & Water Based Fumigation",
    },
    {
      room: "Custom Fumigation",
      price: "-----------",
      type: "Custom Fumigation",
    },
  ];

  return (
    <div className="pricing">
      <div className="pricing-head">
        <img src={pencil} alt="" className="pencil"/>
        <p className="pricing-title">Our Pricing</p>
        <p className="pricing-sub">
          We offer an affordable pricing model for our clients which varies
          depending on the apartment type and fumigation process selected.
        </p>
        <img src={circles} alt="" className="circles"/>
      </div>

      <div className="pricing-section">
        {data.map((item, index) => (
          <div className="pricing-card" key={index}>
            <p className="pricing-room">{item.room}</p>

            <p className="pricing-price">₦{item.price}</p>

            <div className="pricing-type">{item.type}</div>

            <p className="pricing-text">
              This is a {item.type} that covers area like your bed room,
              parlor, kitchen and toilet
            </p>

            <div className="pricing-ul">
              <p className="ul-title">Pest type</p>
              <div className="pricing-li">
                <img src={check} alt="" />
                <p>
                  Rodent <span>(rat, mice)</span>
                </p>
              </div>
              <div className="pricing-li">
                <img src={check} alt="" />
                <p>
                  Insects{" "}
                  <span>
                    (ants, fleas, crickets, beetles, houseflies, bedbugs,
                    mosquitoes)
                  </span>
                </p>
              </div>
              <div className="pricing-li">
                <img src={check} alt="" />
                <p>
                  Arachnids <span> (bugs, mite, spider, ticks, scorpions)</span>
                </p>
              </div>
              <div className="pricing-li">
                <img src={check} alt="" />
                <p>
                  Reptiles <span>(Snakes, wall gecko, lizards)</span>
                </p>
              </div>
            </div>

            <Link className="nav-btn hero-move full" to="/#treatment">
              Book Fumigation
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Pricing;
