import React from "react";
import "../components/services/services.css"

const ServCard = ({title,body, bg, color}) => {
  return (
    <div className={`service-card ${bg}`}>
      <div className="service-head">
        <div className={`service-icon ${color}`}></div>
        <p className="service-header">{title}</p>
      </div>

      <p className="service-body">
        {body}
      </p>
    </div>
  );
};

export default ServCard;
