import React from "react";
import "./priv.css";

const Priv = () => {
  return (
    <div className="privacy">
      <p className="priv-head">Privacy Policy</p>
      <br />
      <p className="priv-body">
        Welcome to <span className="green-col">Foomigeit!</span> We are
        committed to protecting your privacy and ensuring the security of your
        personal information. This Privacy Policy explains how we collect, use,
        and safeguard your data when you use our mobile application ("App"). By
        using <span className="green-col">Foomigeit</span>, you consent to the
        practices described in this Privacy Policy.
        <br />
        <br />
        <span>1. Information We Collect</span>
        <br />
        <span>1.1 Personal Information:</span> When you sign up for{" "}
        <span className="green-col">Foomigeit</span>, we may collect the
        following personal information from you:
        <br />
        - Name
        <br />
        - Email address
        <br />
        - Phone number
        <br />
        - Address
        <br />
        <br />
        <span>1.2 Non-Personal Information:</span> We may also collect
        non-personal information, such as device information, app usage data,
        and technical information (e.g., IP address, operating system, browser
        type) for analytical purposes to improve our App's performance and user
        experience.
        <br />
        <br />
        <span>2. How We Use Your Information</span>
        <br />
        <span>2.1 Service Provision:</span> We use your personal information to
        provide fumigation services as per your requests and bookings through
        the App. This includes sharing your information with pest control
        service providers to schedule and perform the fumigation services.
        <br />
        <br />
        <span>2.2 Communication:</span> We may use your contact details to send
        you important service-related updates, appointment reminders, and
        notifications regarding your bookings and inquiries.
        <br />
        <br />
        <span>2.3 Marketing and Promotions:</span> With your consent, we may use
        your email address to send you marketing materials, special offers, or
        promotional content. You can opt-out of marketing communications at any
        time.
        <br />
        <br />
        <span>2.4 Service Improvement:</span> We may analyze non-personal
        information to understand user behavior and preferences, allowing us to
        enhance the functionality and performance of the App.
        <br />
        <br />
        <span>3. Data Sharing and Disclosure</span>
        <br />
        <span>3.1 Service Providers:</span> We may share your personal
        information with third-party pest control service providers to fulfill
        your booking requests. These service providers are bound by
        confidentiality and privacy obligations and will only use your
        information for the specific purposes related to the services they
        provide.
        <br />
        <br />
        <span>3.2 Legal Compliance:</span> We may disclose your personal
        information when required to comply with applicable laws, regulations,
        or legal processes, or to protect our rights or the safety of others.
        <br />
        <br />
        <span>3.3 Business Transactions:</span> In the event of a merger,
        acquisition, or sale of assets, your personal information may be
        transferred as part of the business transaction. We will notify you of
        any such change in ownership or control of your personal information.
        <br />
        <br />
        <span>4. Data Security</span>
        <br />
        We implement industry-standard security measures to protect your
        personal information from unauthorized access, alteration, or
        disclosure. However, please understand that no method of data
        transmission over the internet or electronic storage is completely
        secure, and we cannot guarantee absolute security.
        <br />
        <br />
        <span>5. Your Rights and Choices</span>
        <br />
        <span>5.1 Access and Correction:</span> You have the right to access and
        update your personal information stored in our App. You can do this
        through your account settings or by contacting us.
        <br />
        <br />
        <span>5.2 Data Deletion:</span> You can request the deletion of your
        account and personal information by contacting us. However, please note
        that some data may be retained for legal, accounting, or legitimate
        business purposes.
        <br />
        <br />
        <span>6. Children's Privacy</span>
        <br />
        <span className="green-col">Foomigeit</span> is not intended for use by
        individuals under the age of 18. We do not knowingly collect personal
        information from children. If you believe that we have unintentionally
        collected information from a child, please contact us immediately, and
        we will take appropriate steps to remove the information.
        <br />
        <br />
        <span>7. Changes to this Privacy Policy</span>
        <br />
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        The updated policy will be posted on the App, and the revised date will
        be indicated at the top. We encourage you to review this Privacy Policy
        periodically.
        <br />
        <br />
        <span>8. Contact Us</span>
        <br />
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or our data practices, please contact us at
        pstcnglimited@gmail.com .
        <br />
        <br />
        By using <span className="green-col">Foomigeit</span>, you agree to the
        terms outlined in this Privacy Policy. Thank you for trusting us with
        your information.
      </p>
      <br />
      <p className="upd">Last Updated: 5/8/2023</p>
    </div>
  );
};

export default Priv;
