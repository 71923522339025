import React from "react";
import Logo from "../imgs/foomigate.svg";
// import Check from "./check.svg";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Delete = () => {
  //   const [view, setView] = useState(false);
  const [load, setLoad] = useState(false);
  const customerId = sessionStorage.getItem("customerId");
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const handleDelete = async () => {
    setLoad(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://api.foomigeit.com/api/v1/Customers/deleteCustomerById/${customerId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setLoad(false);
            // setView(true);
            Swal.fire({
              icon: "success",
              title: "Account Deleted",
              text: "Your details has been removed from our database.",
              timer: 2500,
            });
            setTimeout(() => {
              navigate("/");
            }, 2500);
            sessionStorage.clear();
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
            if (err.message === "Network Error") {
              Swal.fire({
                icon: "error",
                title: err.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Invalid Credentials",
              });
            }
          });
      }
    });
  };

  return (
    <div className="del-body">
      {/* {view ? (
        <div className="del-popup">
          <div className="popup">
            <img src={Check} alt="" className="pop-img" />
            <div className="pop-cont">
              <p className="pop-head">Account Deleted</p>
              <p className="pop-txt">
                Your details has been removed from our database. Thank you for
                choosing us.
              </p>
            </div>
          </div>
        </div>
      ) : (
        false
      )} */}

      <div className="logo-div">
        <img src={Logo} alt="" className="logo" />
      </div>
      <div>
        <p className="del-title">Delete Account</p>
        <p className="del-text">
          We're sorry to see you go, but if you're sure about deleting your
          account, we're here to help.
          <br />
          Account Deletion Process:
          <br />
          Before you proceed with account deletion, please take a moment to
          review the following information:
          <br />
          1. Data Removal: Deleting your account will result in the permanent
          removal of all your account information, including order history,
          preferences, and saved addresses. This action cannot be undone.
          <br />
          2. Active Services: Any active fumigation services or pending orders
          associated with your account will be canceled.
          <br />
          3. Feedback Welcome: If you have a moment, we would appreciate your
          feedback on why you're choosing to delete your account. Your insights
          help us improve our services for others.
          <br />
          Steps to Delete Your Account:
          <br />
          1. Confirm Your Identity: For security purposes, please enter your
          password to confirm that you're the account holder.
          <br />
          2. [Password Field]
          <br />
          3. Review and Confirm: By proceeding, you acknowledge that you have
          read and understood the implications of account deletion.
          <br />
        </p>
        <ul className="del-text">
          <li>
            I understand that my account data will be permanently removed.
          </li>
          <li>
            I understand that any active or pending services will be canceled.
          </li>
          <li>I'm providing feedback (optional).</li>
        </ul>
        <p className="del-text">
          4. [Confirm Button] [Cancel Button]
          <br />
          Important Note:
          <br />
          If you have any questions or concerns before deleting your account,
          please contact our support team at support@foomigeit.com. We're here
          to assist you and address any issues you might be experiencing.
          <br />
          Once you delete your account, you will no longer be able to access
          your account information, order history, or any other account-related
          features.
          <br />
          Thank you for using Foomigeit. If you ever decide to return, we'll be
          here to welcome you back.
        </p>
        <button className="del-acct" onClick={handleDelete}>
          {load ? "Deleting..." : "Delete my Account"}
        </button>
      </div>
    </div>
  );
};

export default Delete;
