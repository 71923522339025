import React, { useState } from "react";
import Logo from "../../imgs/foomigate.svg";
import Menu from "../../imgs/menu-btn.svg";
import "./nav.css";
import { Link } from "react-router-dom";

const New = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="fixed">
      <div className={`nav-contain ${open ? "isOpen" : ""}`}>
        <div className="nav">
          <a href="/#" className="logo-div">
            <img src={Logo} alt="" className="logo" />
          </a>
          <div className="flex desktop">
            <div className="nav-side">
              <Link to="/" className="nav-link">
                HOME
              </Link>
              <Link to="/#about" className="nav-link">
                ABOUT
              </Link>
              <Link to="/#services" className="nav-link">
                SERVICES
              </Link>
            </div>
            <Link to="/#download" className="nav-btn">
              Download our app
            </Link>
          </div>
          <div className="mobile">
            {open ? (
              <p className="close" onClick={() => setOpen(false)}>
                X
              </p>
            ) : (
              <img
                src={Menu}
                alt=""
                className="menu-img"
                onClick={() => setOpen(true)}
              />
            )}
          </div>
        </div>

        {open ? (
          <div className="nav-drop mobile">
            <Link to="/" onClick={() => setOpen(false)} className="nav-link">
              HOME
            </Link>
            <Link
              to="/#about"
              onClick={() => setOpen(false)}
              className="nav-link"
            >
              ABOUT
            </Link>
            <Link
              to="/#services"
              onClick={() => setOpen(false)}
              className="nav-link"
            >
              SERVICES
            </Link>
            <Link
              to="/#treatment"
              onClick={() => setOpen(false)}
              className="nav-link"
            >
              GET STARTED
            </Link>
            <Link
              to="/#download"
              onClick={() => setOpen(false)}
              className="nav-link"
            >
              DOWNLOAD FOOMIGEIT
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default New;
