import "./flyer.css";
import Image from "../../imgs/flyer.svg";

const Flyer = () => {
  return (
    <div className="flyer">
      <img src={Image} alt="" />
    </div>
  );
};
export default Flyer;
