import React from "react";
import ServCard from "../../utils/serv";
import "./services.css";
import pencil from "../../design/serv-pencil.svg";
import vector from "../../design/serv-vector.svg";
import circles from "../../design/serv-circles.svg";
import frame from "../../design/serv-frame.svg";

const Services = () => {
  return (
    <div className="services" id="services">
      <div className="services-contain">
        <img src={pencil} alt="" className="pencil" />
        <ServCard
          title="Residential Service"
          body="We offer a range of fumigation services for residential properties, including single-family homes, apartments, and townhouses."
          bg="yellow"
          color="yel"
        />
        <ServCard
          title="Health care Facilities Fumigation"
          body="We use a range of safe and effective fumigation treatments to eliminate pests, including bed bugs, cockroaches, termites, and rodents."
          bg="purple"
          color="pur"
        />
        <ServCard
          title="⁠Commercial and Industrial Fumigation"
          body="Our commercial fumigation services are designed to protect businesses and their customers from pest infestations."
          bg="green"
          color="gre"
        />
      </div>

      <img src={vector} alt="" className="vector" />

      <div className="services-txt">
        <div className="services-title">
          Our Services <img src={circles} alt="" className="circles" />
        </div>
        <p className="services-sub">
          Our fumigation services are designed to meet the unique needs of your
          property. We start with a thorough inspection to identify any pest
          infestations and determine the best course of action. Our team then
          uses safe and effective fumigation methods to eliminate pests and
          prevent future infestations. We work with you every step of the way to
          ensure your complete satisfaction. Our services are available for both
          residential and commercial properties, so contact us today to schedule
          your fumigation appointment.
        </p>
        <img src={frame} alt="" className="frame" />
      </div>
    </div>
  );
};

export default Services;
