import React from "react";
import "./download.css";
import Play from "../../imgs/play.svg";
import App from "../../imgs/store.svg";

const Download = () => {
  return (
    <div className="download-contain">
      <div className="download">
        <div className="download-title">Download Foomigeit</div>
        <div className="download-flex">
          <div className="download-txt">
            Get our <span>Mobile App</span> on both App Store & Play Store.
          </div>
          <div className="download-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=app.web.foomigate&pcampaignid=web_share"
              target="_blank"
              rel="noreferrer"
              className="playstore"
            >
              <img src={Play} alt="" />
              <div className="play-txt">
                <p className="play-body">GET IT ON</p>
                <p className="play-name">Google Play</p>
              </div>
            </a>
            <a
              href="https://apps.apple.com/ng/app/foomigeit/id6465175752"
              target="_blank"
              rel="noreferrer"
              className="playstore"
            >
              <img src={App} alt="" />
              <div className="play-txt">
                <p className="play-body">GET IT ON</p>
                <p className="play-name">App Store</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
