import React from "react";
import "../priv/priv.css";

const Terms = () => {
  return (
    <div className="privacy">
      <p className="priv-head">Terms and Conditions</p>
      <br />
      <p className="priv-body">
        Welcome to <span className="green-col">Foomigeit!</span> These Terms and Conditions ("Terms") govern your
        use of the <span className="green-col">Foomigeit</span> mobile application ("App"), provided by [Pest
        Solutions Technology Compay] ("we," "us," or "our"). By using the App,
        you agree to these Terms in full. If you do not agree with any part of
        these Terms, please refrain from using the App.
        <br />
        <br />
        <span>1. Applicability and Scope</span>
        <br />
        <span>1.1 Eligibility:</span> <span className="green-col">Foomigeit</span> is intended for use by individuals who are at
        least 18 years old. By using the App, you represent and warrant that you
        meet this eligibility requirement.
        <br />
        <br />
        <span>1.2 Service Area:</span> Our fumigation services are currently available in
        specific geographical regions. By using the App, you confirm that you
        are within our service area.
        <br />
        <br />
        <span>2. Service Booking and Contract</span>
        <br />
        <span>2.1 Service Requests:</span> The App allows you to request fumigation services
        for your home or business. By submitting a service request through the
        App, you are making an offer to enter into a contract with us.
        <br />
        <br />
        <span>2.2 Service Confirmation:</span> Your service request will be subject to
        acceptance by us. Once we confirm the availability of our services and
        finalize the details with you, a contract will be formed between you and
        us.
        <br />
        <br />
        <span>2.3 Service Terms:</span> The specific terms of the fumigation service,
        including the type of service, date, time, and pricing, will be agreed
        upon before the service is rendered.
        <br />
        <br />
        <span>3. Payment and Pricing</span>
        <br />
        <span>3.1 Payment Authorization:</span> By using the App to request fumigation
        services, you authorize us to charge the agreed-upon fees to your chosen
        payment method.
        <br />
        <br />
        <span>3.2 Pricing:</span> The pricing of our services will be clearly presented to
        you before you confirm your booking. We reserve the right to adjust our
        pricing at any time, but any changes will not affect the agreed-upon
        pricing for confirmed bookings.
        <br />
        <br />
        <span>3.3 Payment Disputes:</span> If you believe there is an error in the charges
        applied to your account, please contact us within 48hours from
        the date of the transaction.
        <br />
        <br />
        <span>4. Cancellation and Rescheduling</span>
        <br />
        <span>4.1 Cancellation Policy:</span> If you need to cancel a scheduled fumigation
        service, you must do so within 48hours before the
        scheduled service time. Failure to cancel within the specified period
        may result in a cancellation fee.
        <br />
        <br />
        <span>4.2 Rescheduling:</span> You may request to reschedule your service by
        contacting us within a reasonable time before the scheduled service
        time, subject to our availability.
        <br />
        <br />
        <span>5. Your Responsibilities</span>
        <br />
        <span>5.1 Property Access:</span> You must ensure that our team has safe and
        unrestricted access to the premises requiring fumigation at the
        scheduled time.
        <br />
        <br />
        <span>5.2 Preparations:</span> Prior to the fumigation service, you must follow any
        preparation instructions provided by us to ensure the effectiveness and
        safety of the service.
        <br />
        <br />
        <span>6. Disclaimer of Warranty</span>
        <br />
        <span>6.1 Limitation of Liability:</span> While we strive to deliver high-quality
        services, we do not warrant or guarantee the complete eradication of
        pests or the absence of any adverse effects. Our liability for any
        damages arising from the provision of our services is limited to the
        amount paid for the specific service.
        <br />
        <br />
        <span>7. Intellectual Property</span>
        <br />
        <span>7.1 Ownership:</span> The App and its contents, including but not limited to
        logos, text, graphics, and images, are the intellectual property of
        <span className="green-col">Foomigeit</span>, and are protected by copyright and other intellectual
        property laws.
        <br />
        <br />
        <span>7.2 Use Restrictions:</span> You may not reproduce, distribute, modify, or
        exploit any part of the App without our prior written consent.
        <br />
        <br />
        <span>8. Modifications to the App and Terms</span>
        <br />
        <span>8.1 App Changes:</span> We reserve the right to modify, suspend, or discontinue
        any aspect of the App at any time, with or without notice.
        <br />
        <br />
        <span>8.2 Terms Changes:</span> We may update these Terms from time to time to
        reflect changes in our practices or for other operational, legal, or
        regulatory reasons. The updated Terms will be posted on the App, and the
        revised date will be indicated at the top. Your continued use of the App
        after the updated Terms have been posted constitutes your acceptance of
        the revised Terms.
        <br />
        <br />
        <span>9. Governing Law and Dispute Resolution</span>
        <br />
        <span>9.1 Governing Law:</span> These Terms are governed by and construed in
        accordance with the laws of Nigeria.
        <br />
        <br />
        <span>9.2 Dispute Resolution:</span> Any disputes arising out of or related to these
        Terms shall be resolved through mediation or arbitration in accordance
        with the rules of [Arbitration/Mediation Institution]. The decision of
        the mediator or arbitrator shall be final and binding.
        <br />
        <br />
        <span>10. Contact Us</span>
        <br />
        If you have any questions, concerns, or inquiries regarding these Terms,
        please contact us at pstcnglimited@gmail.com .
        <br />
        Thank you for choosing <span className="green-col">Foomigeit!</span> We look forward to serving you with
        our fumigation services.
      </p>
      <br /> <p className="upd">Last Updated: 8/4/2023</p>
    </div>
  );
};

export default Terms;
